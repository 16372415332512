import React, { useState } from "react";
import Button from "@mui/material/Button";
import BaseDialog from "./BaseDialog";

import { useSetRecoilState, useRecoilState } from "recoil"; // <--- Add this line
import { selectAllState, CountActions } from "../services/atoms";

function BaseButton(props) {
  const setSelectAll = useSetRecoilState(selectAllState);
  const [countActions, setCountActions] = useRecoilState(CountActions);

  const V1Color = "linear-gradient(#00BFFF 0%, #E0FFFF)"; // Blue shades
  const V2Color = "linear-gradient(#C99FE3 0% ,#FFE8FF)"; // Purple shades
  const V3Color = "linear-gradient(#72DC8F 0% ,#CFFFC2)"; // Green shades
  const V4Color = "linear-gradient(#F27575 0% ,#F8D6D6)"; // Red shades

  const [themeColor] = useState(
    props.color === "V1"
      ? V1Color
      : props.color === "V2"
      ? V2Color
      : props.color === "V3"
      ? V3Color
      : props.color === "V4"
      ? V4Color
      : "white"
  );

  const [textColor] = useState(
    props.color === "V1"
      ? "rgb(0, 191, 255)" // Changed from rgba to rgb and removed the opacity value
      : props.color === "V2"
      ? "rgb(201, 159, 227)" // Changed from rgba to rgb and removed the opacity value
      : props.color === "V3"
      ? "rgb(114, 220, 143)" // Changed from rgba to rgb and removed the opacity value
      : props.color === "V4"
      ? "rgb(242, 117, 117)" // Changed from rgba to rgb and removed the opacity value
      : "black"
  );

  const formatButtonText = (text) => {
    const formattedText = text.split("").map((char, index) => {
      if (index === 0) {
        return (
          <span key={index} style={{ textTransform: "uppercase" }}>
            {char}
          </span>
        );
      } else {
        return char.toLowerCase();
      }
    });

    return <span>{formattedText}</span>;
  };

  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setOpen(!open);
    if (props.onClick && !open) {
      props.onClick();
    }
    setCountActions((countActions) => countActions + 1);
    setSelectAll(false); // Reset the selectAll state to false when the button is clicked
  };

  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        background: themeColor, // Set the new background color here
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important",
        transition: "background-color 0.8s ease, box-shadow 0.8s ease",
        "&:hover": {
          backgroundColor: "#e0e0e0 !important", // Adjusted the hover color to be slightly darker for a hover effect
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important",
        },
        "&:active": {
          backgroundColor: "#d0d0d0 !important", // Adjusted the active color to be even darker for a click effect
          boxShadow: "none !important",
        },
        "&.Mui-focusVisible": {
          backgroundColor: "#f0f0f0 !important", // Set the new background color here as well for focus state
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important",
        },
        fontFamily: "Raleway",
        width: "90%",
        height: "3rem",
        borderRadius: "8px",
        textTransform: "none",
        fontSize: "1rem",
        mr: 1,
        padding: "0",
        border: "0.1px solid black",
      }}
      onClick={handleClick}
    >
      <span
        style={{
          color: "black",
          fontSize: "0.9rem",
        }}
      >
        {formatButtonText(props.txt)}
      </span>
      <BaseDialog
        open={open}
        setOpen={setOpen}
        themeColor={themeColor}
        txt={props.txt.toLowerCase()}
      />
    </Button>
  );
}

export default BaseButton;
