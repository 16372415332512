import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";

import ExtendProfileAvatar from "./ExtendProfileAvatar";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { _friendsList } from "../services/atoms";
import { selectAllState } from "../services/atoms";
import Button from "@mui/material/Button";

export default function StandardImageList(props) {
  const theme = useTheme();

  const [friendsList, setFriendList] = useRecoilState(_friendsList);
  const [selectAll, setSelectAll] = useRecoilState(selectAllState);
  const isMobile = useMediaQuery("(max-width:768px)");
  const calculateCols = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= theme.breakpoints.values.xl) {
      return 14; // xl desktops size
    } else if (screenWidth >= theme.breakpoints.values.lg) {
      return 12; // desktops size
    } else if (screenWidth >= theme.breakpoints.values.md) {
      return 10; // laptops size
    } else if (screenWidth >= theme.breakpoints.values.sm) {
      return 8; // tablets size
    } else {
      return 4; //mobile size
    }
  };

  const [cols, setCols] = useState(calculateCols);

  useEffect(() => {
    const handleResize = () => {
      setCols(calculateCols());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to handle selecting all items
  const handleSelectAllItems = () => {
    setSelectAll(true);
    const allNames = props.friendsList.map((item) => item.username);
    setFriendList(allNames);
  };

  // Function to handle unselecting all items
  const handleUnselectAllItems = () => {
    setSelectAll(false);
    setFriendList([]);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: isMobile ? "100%" : "50vh", // Full height on mobile, shorter height on PC
        width: isMobile ? "100%" : "90%",
        margin: isMobile ? "0" : "0 auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: 0.5,
          marginTop: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            ml: 1.5,
            fontSize: "0.8rem",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{friendsList.length}</span>
          &nbsp;selected
        </Typography>
        <Box sx={{ display: "flex", gap: 1, ml: 1 }}>
          <Button
            variant="contained"
            size="small"
            onClick={selectAll ? handleUnselectAllItems : handleSelectAllItems}
            sx={{
              height: "20px",
              py: 0.2,
              fontSize: "0.6rem",
              borderRadius: "5px",
              border: "1px solid black",
              background: "#333", // Dark gray background
              color: "white", // White text
              "&:hover, &:active, &:focus": {
                background: "#444", // Slightly lighter gray on hover, active, and focus
              },
            }}
          >
            {selectAll ? "Unselect All" : "Select All"}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "99%",
          border: "2px solid black",
          ml: 0,
          height: "335px",
          overflowY: "auto",
          mb: 1,
        }}
      >
        <ImageList
          sx={{
            width: "99%",
            overflowX: "hidden",
            marginTop: 0,
            marginRight: "auto",
            boxSizing: "border-box",
          }}
          cols={isMobile ? 5 : 8}
          rowHeight={95}
        >
          {Array.isArray(props.friendsList) &&
            props.friendsList.map((item, index) => (
              <ImageListItem
                key={index}
                sx={{
                  boxSizing: "border-box",
                  marginTop: "5px",
                  marginBottom: "0px",
                  padding: "0px",
                  Height: "95px",
                  display: "block",
                  "& img": {
                    maxWidth: "95%",
                    borderRadius: "50%",
                  },
                }}
              >
                <ExtendProfileAvatar
                  name={item.username}
                  img={item.profile_pic_url}
                />
              </ImageListItem>
            ))}
        </ImageList>
      </Box>
    </Box>
  );
}
