import React from 'react'
import PagesHeader from "../components/PagesHeader";



function Statistics() {
    return (
        <div>
            <PagesHeader txt="Statistics" />
        </div>
    )
}

export default Statistics