import React from 'react';
import Tip from './Tip';
import Stack from '@mui/material/Stack';
import {TipsList} from '../services/Data';

function Tips() {

  return (
    <Stack spacing={4}>
      {TipsList.map((tip, index) => (
        <Tip
          key={index}
          header={tip.header}
          body={tip.body}
          likes={tip.likes}
        />
      ))}
    </Stack>
  );
}

export default Tips;
