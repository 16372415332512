import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography, Button, IconButton, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { selectedTimeAtom } from '../services/atoms';
import CloseIcon from '@mui/icons-material/Close';

function TimeSettingComponent({ onClick, open, handleClose }) {
    const [selectedTime, setSelectedTime] = useRecoilState(selectedTimeAtom) || {
        hour: '03',
        minute: '00',
    };
    const [selectedHour, setSelectedHour] = useState('03');
    const [selectedMinute, setSelectedMinute] = useState('00');
    const [loading, setLoading] = useState(false); // State to manage loading

    const hours = useMemo(() => {
        return Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
    }, []);

    const minutes = useMemo(() => {
        return Array.from({ length: 12 }, (_, i) =>
            (i * 5).toString().padStart(2, '0')
        );
    }, []);

    const hourBoxRef = useRef(null);
    const minuteBoxRef = useRef(null);

    useEffect(() => {
        if (open) {
            const hourIndex = hours.indexOf(selectedTime.hour.padStart(2, '0'));
            const minuteIndex = minutes.indexOf(
                selectedTime.minute.padStart(2, '0')
            );
            setSelectedHour(selectedTime.hour.padStart(2, '0'));
            setSelectedMinute(selectedTime.minute.padStart(2, '0'));

            if (hourBoxRef.current) {
                hourBoxRef.current.scrollTop =
                    (hourIndex / hours.length) * hourBoxRef.current.scrollHeight;
            }
            if (minuteBoxRef.current) {
                minuteBoxRef.current.scrollTop =
                    (minuteIndex / (minutes.length * 2)) *
                    minuteBoxRef.current.scrollHeight;
            }
        }
    }, [open, selectedTime, hours, minutes]);

    const handleScroll = (e, setSelected) => {
        const itemHeight = 40; // Adjust this value according to your design
        const selectedIndex = Math.floor(
            e.target.scrollTop / itemHeight + 0.5
        );

        if (setSelected) {
            setSelected(
                setSelected === setSelectedHour
                    ? hours[selectedIndex]
                    : minutes[selectedIndex]
            );
        }
    };

    const handleSetTime = () => {
        setSelectedTime({
            hour: selectedHour.padStart(2, '0'),
            minute: selectedMinute.padStart(2, '0'),
        });
        handleClose();
    };

    const handlePerformNow = async () => {
        setLoading(true); // Show loading indicator
        await onClick(); // Perform the actions
        setLoading(false); // Hide loading indicator after actions are performed
    };
    
    

    return (
        <Box
            sx={{
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: open ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 9999,
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 4,
                    width: '300px',
                    borderRadius: '10px',
                }}
            >
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        bgcolor: 'grey',
                        borderRadius: '50%', // Add border radius for a circular button
                        padding: '5px', // Adjust padding for size
                        '&:hover': {
                            bgcolor: 'grey',
                        },
                    }}
                >
                    <CloseIcon sx={{ color: 'black', fontSize: '18px' }} /> {/* Adjust font size */}
                </IconButton>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    marginBottom="20px"
                    sx={{ fontSize: '14px' }}
                >
                    Choose end of day when device is not busy. Pick time:
                </Typography>

                <Box
                    id="time-options-box"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'stretch', // Ensure equal heights
                        justifyContent: 'space-around',
                        border: '1px solid black',
                        p: 2,
                        marginBottom: '20px',
                    }}
                >
                    <Box
                        id="hour-box"
                        ref={hourBoxRef}
                        sx={{
                            overflowY: 'scroll',
                            maxHeight: '140px', // Adjust this value
                            borderRight: '1px solid black',
                            width: '50%',
                        }}
                        onScroll={(e) => handleScroll(e, setSelectedHour)}
                    >
                        {hours.map((hour) => (
                            <Typography
                                key={hour}
                                onClick={() => setSelectedHour(hour)}
                                style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    backgroundColor:
                                        hour === selectedHour ? 'lightblue' : 'transparent',
                                    padding: '10px 0',
                                }}
                            >
                                {hour}
                            </Typography>
                        ))}
                    </Box>
                    <Box
                        id="minute-box"
                        ref={minuteBoxRef}
                        sx={{
                            overflowY: 'scroll',
                            maxHeight: '140px', // Equal height to hour box
                            width: '50%',
                        }}
                        onScroll={(e) => handleScroll(e, setSelectedMinute)}
                    >
                        {minutes.map((minute) => (
                            <Typography
                                key={minute}
                                onClick={() => setSelectedMinute(minute)}
                                style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    backgroundColor:
                                        minute === selectedMinute
                                            ? 'lightblue'
                                            : 'transparent',
                                    padding: '10px 0',
                                }}
                            >
                                {minute}
                            </Typography>
                        ))}
                    </Box>
                </Box>

                <Button
                    variant="contained"
                    onClick={handleSetTime}
                    sx={{ display: 'block', mx: 'auto' }}
                >
                    Set
                </Button>
                <Button
                    variant="contained"
                    onClick={handlePerformNow}
                    sx={{
                        borderRadius: '7px',
                        background: '#64b5f6', // Brighter blue color
                        color: "white", // Change text color to white
                        border: "none", // Remove border
                        fontFamily: 'Raleway',
                        textTransform: 'none',
                        padding: '4px 16px',
                        width: '50%', // Adjust width to match second button
                        marginTop: '1rem', // Add margin top
                        display: 'block', // Ensure button is displayed as block element
                        marginLeft: 'auto', // Center the button horizontally
                        marginRight: 'auto', // Center the button horizontally
                    }}
                >
                    {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "Perform Now"}
                </Button>
            </Box>
        </Box>
    );
}

export default TimeSettingComponent;
