import React, { useEffect, useState } from "react";
import LampOff from "../images/LampOff.png";
import LampOn from "../images/LampOn.png";

function Tip(props) {
  const [userLiked, setUserLiked] = useState(false);

  const handleLikeClick = () => {
    setUserLiked(!userLiked);
  };

  return (
    <div
      style={{
        width: "80%",
        marginLeft: "10%",
        marginRight: "10%",
        textAlign: "left",
      }}
    >
      <span>
        • {props.header}
        <br />
        {props.body}
      </span>
      <br />
      {userLiked ? (
        <div>
          <img
            src={LampOn}
            alt="Light Bulb On"
            onClick={handleLikeClick}
            style={{
              height: "32px",
              verticalAlign: "middle",
              marginRight: "5px",
              marginTop: "4px",
            }}
          />
          <span style={{ verticalAlign: "middle" }}>{props.likes + 1}</span>
        </div>
      ) : (
        <div>
          <img
            src={LampOff}
            alt="Light Bulb Off"
            onClick={handleLikeClick}
            style={{
              height: "32px",
              verticalAlign: "middle",
              marginRight: "5px",
              marginTop: "4px",
            }}
          />
          <span style={{ verticalAlign: "middle" }}>{props.likes}</span>
        </div>
      )}
    </div>
  );
}

export default Tip;
