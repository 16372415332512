import React from 'react';

const DynamicArrow = ({ top, right, bottom = '65%', left }) => {
  const containerStyle = {
    position: 'fixed',
    top: top,
    right: right,
    bottom: bottom,
    left: '25%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000, // Ensures the arrow is above most other elements
    pointerEvents: 'none', // Ensures the arrow does not interfere with other clickable elements
    animation: 'bounce 2s infinite' // Applies bouncing animation
  };

  const arrowLineStyle = {
    position: 'absolute',
    bottom: '0',  // Starts the line from the bottom of the container
    left: '50%',
    width: '2px',
    height: '50px',
    backgroundColor: 'black',
    transform: 'translateX(-50%)',
  };

  const arrowHeadStyle = {
    position: 'absolute',
    bottom: '50px',  // Positions the arrowhead directly above the line
    left: '50%',
    marginLeft: '-10px',
    width: '0',
    height: '0',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid black',  // Creates an upward-pointing arrowhead
  };

  const keyframesStyle = `
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-10px); // Bounce up
      }
      60% {
        transform: translateY(-5px); // Smaller bounce for smoother animation
      }
    }
  `;

  return (
    <>
      <style>{keyframesStyle}</style>
      <div style={containerStyle}>
        <div style={arrowLineStyle} />
        <div style={arrowHeadStyle} />
      </div>
    </>
  );
};

export default DynamicArrow;
