import React, { useState } from 'react';
import Tag from './Tag';
import { Box } from '@mui/material';

function TagStack({ tagList, onSelectTag, defaultSelectedTag }) {
    const [selectedTag, setSelectedTag] = useState(defaultSelectedTag);

    const handleTagSelect = (label) => {
        setSelectedTag(label);
        onSelectTag(label, selectedTag !== label);
    };
    return (
        <Box display="flex" flexDirection="row" sx={{ overflowX: "auto", }} pl={0}>
            {tagList.map((tag, index) => (
                <Box key={tag} mr={-1} sx={{ flexShrink: 0, minWidth: '0px'}}> {/* Adjust minWidth as necessary */}
                    <Tag
                    p={0}
                        label={tag}
                        onSelect={handleTagSelect}
                        selected={selectedTag === tag}
                    />
                </Box>
            ))}
        </Box>
    );
}

export default TagStack;
