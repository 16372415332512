import React from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

function SearchBar(props) {

    const handleTextInput = (e) => {
        console.log(e.target.value);
    }

    return (
        <TextField
            id="search"
            type="search"
            size='small'
            placeholder="Search"  
            sx={{
                width: "95%",
            }}
            InputProps={{
                sx: { borderRadius: 3 },
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            onChange={props.handleTextInput ? props.handleTextInput : handleTextInput}
        />
    )
}

export default SearchBar
