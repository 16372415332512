import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HomeIcon from "@mui/icons-material/Home";
import MenuPinIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { useRecoilState } from "recoil";
import { _loggedUser, CountLaunch } from "../services/atoms";
import Logo from "../images/Social_Order_Logo2.png";
import AdsBar from "./AdsBar";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { _friendsList } from "../services/atoms";
import HistoryIcon from "@mui/icons-material/History";
import SendIcon from "@mui/icons-material/Send";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import useMediaQuery from "@mui/material/useMediaQuery";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [countLaunch, setCountLaunch] = useRecoilState(CountLaunch);

  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/";
  const [friendsList, setFriendsList] = useRecoilState(_friendsList);
  const isMobile = useMediaQuery("(max-width:768px)");

  const handleNavClick = (event, dir) => {
    navigate(dir);
    if (dir == "/History") setCountLaunch((countLaunch) => countLaunch + 1);
  };

  const tabValue = {
    "/home": "/home",
    "/History": "/History",
  };

  const handleMenuNavClick = (dir) => {
    switch (dir) {
      case "Tips":
        navigate("/Tips");
        break;
      case "Privacy Policy":
        navigate("/PrivacyPolicy");
        break;
      case "About us":
        navigate("/AboutUs");
        break;
      case "Feedback":
        navigate("/Feedback");
        break;
      case "Log out":
        setFriendsList([]); // Clear the friends list
        navigate("/login");
        break;
      default:
        navigate("*");
    }

    window.scrollTo(0, 0); // Ensure the page starts from the top after navigation
  };

  const [state, setState] = useState({
    bottom: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, bottom: open });
  };

  const list = () => (
    <Box
      sx={{ auto: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {["Tips", "Privacy Policy", "About us", "Feedback", "Log out"].map(
          (text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => handleMenuNavClick(text)}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  const [loggedUser, setLoggedUser] = useRecoilState(_loggedUser);

  const LogoBar = createTheme({
    palette: {
      primary: {
        main: "#ffffff", // Set the primary color to white
      },
    },
  });

  if (isLoginPage) {
    // If on the login page, render nothing (null)
    return null;
  }
  return (
    <div>
      {isLoginPage ? (
        <></>
      ) : (
        <div>
          <ThemeProvider theme={LogoBar}>
            <AppBar
              elevation={0}
              position="fixed"
              sx={{ top: 0, left: 0, width: "100%" }}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 1%",
                }}
              >
                {/* Logo Section */}
                <Box
                  sx={{
                    textAlign: "left",
                    alignItems: "center",
                    display: "inline-flex",
                    flexDirection: "column",
                    width: "auto",
                    maxWidth: "200px",
                    ml: 2,
                  }}
                >
                  <img
                    alt="Logo"
                    src={Logo}
                    style={{ width: "100%", height: "auto", maxWidth: "160px" }}
                  />
                </Box>

                {/* Name Section */}
                <Box
                  sx={{
                    textAlign: "right",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "auto",
                    mr: 4,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "1.5em",
                    }}
                  >
                    {loggedUser !== "" ? <>{loggedUser}</> : null}
                  </Typography>
                </Box>
              </Toolbar>
            </AppBar>
          </ThemeProvider>

          {/* <Box sx={{ mt: 8 }}>
            <AdsBar />
          </Box> */}

          <Box
            sx={{
              flexGrow: 1,
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              bgcolor: "#FFFFFF",
              zIndex: 999,
              height: isMobile ? "auto" : "15vh", // Set height to auto on mobile, 15vh on larger screens
            }}
          >
            <Tabs
              value={tabValue[location.pathname] || false}
              onChange={handleNavClick}
              aria-label="icon tabs nav"
            >
              <Tab
                icon={<HomeIcon sx={{ mt: "5%" }} />}
                label="Home"
                aria-label="home"
                value="/home"
                sx={{ width: "33.3%", height: "1px" }} // Adjust the height here
              />
              <Tab
                icon={<RocketLaunchIcon sx={{ mt: "5%" }} />}
                label="Launch"
                aria-label="launch"
                value="/History"
                sx={{ width: "33.3%", height: "1px" }} // Adjust the height here
              />
              <Tab
                icon={<MenuPinIcon sx={{ mt: "5%" }} />}
                label="Menu"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                value={false}
                sx={{ width: "33.3%", height: "1px" }} // Adjust the height here
              />
            </Tabs>
          </Box>

          <React.Fragment key="bottom">
            <SwipeableDrawer
              anchor="bottom"
              open={state["bottom"]}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              {list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
    </div>
  );
}

export default Navbar;
