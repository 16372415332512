import React, { useState } from 'react'; // added useState import
import './App.css';
import Home from "./pages/Home";
import Statistics from './pages/Statistics';
import Feedback from "./pages/Feedback";
import History from "./pages/HistoryActions";
import Login from "./pages/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";
import Registration from "./pages/Registration";
import Settings from "./pages/Settings";
import Tips from "./pages/Tips";
import ErrorPage from './pages/ErrorPage';
import AboutUs from "./pages/AboutUs";
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import { RecoilRoot } from "recoil";
import { useLocation } from 'react-router-dom';
import useInstallPrompt from './hooks/useInstallPrompt';

function App() {
  const location = useLocation();
  const [installPromptEvent, handleInstall, setInstallPromptEvent] = useInstallPrompt();
  const [showDownloadedPopup, setShowDownloadedPopup] = useState(false); // Added this state for the popup

  const isLoginPage = location.pathname === "/login" || location.pathname === '/';
  const YellowGradient = "linear-gradient(#FFD700 0%, #FFFBAA 100%)"; // Adjusted Yellow shades to be brighter


  const appStyles = {
    marginTop: isLoginPage ? 0 : '80px',
    paddingBottom: isLoginPage ? 0 : '106px',
  };

  function handleInstallAndUpdatePopup() {
    if (installPromptEvent) {
      installPromptEvent.prompt();

      installPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setShowDownloadedPopup(true);
        }
        setInstallPromptEvent(null);  // Use the setter here
      });
    }
  }

  return (
    <RecoilRoot>
      <div className="App">
        {installPromptEvent && isLoginPage && (
          <button
            style={{
              position: 'fixed',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '20px 40px',
              backgroundColor: 'blue',
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              fontSize: '18px',
              cursor: 'pointer',
              zIndex: 1000
            }}
            onClick={handleInstallAndUpdatePopup} // Updated to new function
          >
            Install App
          </button>
        )}

        {showDownloadedPopup && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%', // Adjusted for broader width
            height: '30%', // Adjusted for broader height
            padding: '40px 60px', // Adjusted padding for more spacing
            backgroundColor: 'white',
            backgroundImage: YellowGradient, // Added this line
            border: '1px solid #ccc',
            borderRadius: '8px',
            fontSize: '26px',
            zIndex: 1001,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            The app was downloaded to your home screen. <br />Go check it out!
            <button
              style={{
                marginTop: '30px',
                padding: '15px 30px',       // Increased padding for bigger size
                fontSize: '20px',          // Increased font size
                borderRadius: '10px'       // Rounded edges with a bigger radius
              }}
              onClick={() => setShowDownloadedPopup(false)}
            >
              Close
            </button>
          </div>
        )}

        <Navbar />

        <div style={appStyles}>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/home' element={<Home />} />
            <Route path='/stats' element={<Statistics />} />
            <Route path='/History' element={<History />} />
            <Route path='/Feedback' element={<Feedback />} />
            <Route path='/Login' element={<Login />} />
            <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
            <Route path='/Profile' element={<Profile />} />
            <Route path='/Registration' element={<Registration />} />
            <Route path='/Settings' element={<Settings />} />
            <Route path='/Tips' element={<Tips />} />
            <Route path='/AboutUs' element={<AboutUs />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </div>
      </div>
    </RecoilRoot>
  );
}

export default App;
