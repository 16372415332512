import { useEffect, useState } from 'react';

function useInstallPrompt() {

    const [installPromptEvent, setInstallPromptEvent] = useState(null);

    useEffect(() => {
        const beforeInstallPromptHandler = (event) => {
            event.preventDefault(); // Prevent the automatic prompt
            setInstallPromptEvent(event.detail || event); // Use the mock stored in the detail property or the real event
        };

        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

        return () => {
            window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        };
    }, []);


    const handleInstall = () => {
        if (!installPromptEvent) return;
        installPromptEvent.prompt(); // Show the install prompt
        setInstallPromptEvent(null); // Clear the stored event
    };

    return [installPromptEvent, handleInstall, setInstallPromptEvent];
}

export default useInstallPrompt;
