import React from 'react'
import NameChip from './NameChip'
import { useRecoilState } from "recoil";
import { _friendsList } from "../services/atoms";
import Stack from '@mui/material/Stack';


function NameChipStack() {

    const [friendsList, setFriendList] = useRecoilState(_friendsList)

    return (
        <div
        style={{
            display: "flex",
            justifyContent: "center", // Horizontally center the content
            alignItems: "center", // Vertically center the content
            height: "20px", // Make the container full height of the viewport
            marginBottom:"20px"
        }}
    >
        <Stack
            direction="row"
            spacing={1}
            sx={{
                display: "flex",
                width: "90%",
                overflowY: "scroll",
                // border: "2px solid black",
            }}
        >
            {friendsList.map((name, index) => (
                <NameChip key={index} name={name} />
            ))}
        </Stack>
    </div>
    )
}

export default NameChipStack