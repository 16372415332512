import React from 'react';
import { useRecoilState } from 'recoil';
import { _unfollowedList, _muteStoryList, _mutePostList } from '../services/atoms';
import { Box, Typography, Button } from '@mui/material';
import userMap from '../services/userMap.json';

export default function FriendsListForHistory({ selectedTab, friendsList }) {
  const [unfollowedList, setUnfollowedList] = useRecoilState(_unfollowedList);
  const [muteStoryList, setMuteStoryList] = useRecoilState(_muteStoryList);
  const [mutePostList, setMutePostList] = useRecoilState(_mutePostList);

  function getFilteredFriends(selectedTab) {
    switch (selectedTab) {
      case 'unfollow':
        return friendsList.filter(friend => unfollowedList.includes(friend.username));
      case 'muteStories':
        return friendsList.filter(friend => muteStoryList.includes(friend.username));
      case 'mutePosts':
        return friendsList.filter(friend => mutePostList.includes(friend.username));
      default:
        return friendsList;
    }
  }

  function getActionButtonLabelAndOnClick(item) {
    switch (selectedTab) {
      case 'unfollow':
        return item.unfollowed ? ['X', () => console.log(`Follow ${item.username}`)] : [null, null];
      case 'muteStories':
        return item.mutedStory ? ['X', () => console.log(`Unmute Story ${item.username}`)] : [null, null];
      case 'mutePosts':
        return item.mutedPost ? ['X', () => console.log(`Unmute Post ${item.username}`)] : [null, null];
      default:
        return [null, null];
    }
  }

  function handleAction(username) {
    switch (selectedTab) {
      case 'unfollow':
        setUnfollowedList((prevList) => prevList.filter((item) => item !== username));
        break;
      case 'muteStories':
        setMuteStoryList((prevList) => prevList.filter((item) => item !== username));
        break;
      case 'mutePosts':
        setMutePostList((prevList) => prevList.filter((item) => item !== username));
        break;
      default:
        break;
    }
  }

  const sortedFilteredFriendsList = getFilteredFriends(selectedTab)
    .map(friend => {
      const userMapDetails = userMap[friend.username];
      return {
        ...friend,
        profile_pic_url: userMapDetails ? userMapDetails.profile_pic_url : friend.photoURL,
        unfollowed: unfollowedList.includes(friend.username),
        mutedStory: muteStoryList.includes(friend.username),
        mutedPost: mutePostList.includes(friend.username),
      };
    })
    .filter(friend => friend.unfollowed || friend.mutedStory || friend.mutedPost)
    .sort((a, b) =>
      (b.unfollowed + b.mutedStory + b.mutedPost) -
      (a.unfollowed + a.mutedStory + a.mutedPost)
    );

  console.log(sortedFilteredFriendsList);

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      width: '100%',
      overflowY: 'auto',
    }}>
      <Box
        sx={{
          width: '100%',
          padding: '0px',
          boxSizing: 'border-box',
        }}
      >
        {sortedFilteredFriendsList.map((item) => (
          <Box key={item.id || item.username}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px',
              borderBottom: '1px solid #ccc',
            }}
          >
            <Box
              sx={{
                marginRight: '16px',
                marginLeft: '10px', // Adjusted left margin
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                overflow: 'hidden',
                position: 'relative', // Added position
              }}
            >
              <img
                src={item.profile_pic_url}
                alt={item.username}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '50%', // Ensures the image is always a circle
                }}
              />
            </Box>
            <Box sx={{ flex: 2, marginRight: '8px', marginLeft: '10px', textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              <Typography variant="body1">{item.username}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '100px', // Adjusted right margin
                textAlign: 'left',   // Added text alignment
              }}
            >
              {
                (() => {
                  const [label, onClick] = getActionButtonLabelAndOnClick(item);
                  return label ? (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleAction(item.username)}
                      sx={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        padding: 0,
                        minWidth: 0,
                        backgroundColor: '#f0f0f0',
                        borderColor: 'black',
                        color: 'black',
                        position: 'relative',
                        left: '-10px', // Adjusted left position
                        '&:hover': {
                          backgroundColor: '#d0d0d0',
                        },
                        '&:active': {
                          backgroundColor: '#f0f0f0',
                        },
                        '&:focus': {
                          backgroundColor: '#f0f0f0',
                        },
                      }}
                    >
                      {label}
                    </Button>
                  ) : null;
                })()
              }
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
