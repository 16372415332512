
export const TipsList = [
    {
        header: "Set limits for yourself.",
        body: "Decide how much time you want to spend on Instagram each day, and stick to it.You can use a timer or app to help you track your time.",
        likes: 36
    },
    {
        header: "Unfollow accounts that you don't care about.",
        body: " If you're constantly scrolling through your feed" +
            "and seeing things that don't interest you, it's time" +
            "to unfollow those accounts." +
            "This will help you focus on the content that you" +
            "do care about.",
        likes: 12
    },
    {
        header: "Take breaks from Instagram.",
        body: "If you find yourself spending too much time on Instagram, take a break. Delete the app from your phone for a few days or weeks, and see how you feel.",
        likes: 71
    },
    {
        header: "Find other activities to fill your time.",
        body: "If you're bored, you're more likely to turn to Instagram. Find other activities to do, such as reading, going for a walk, or spending time with friends and family.",
        likes: 54
    },
    
]
