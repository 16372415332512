import React, { useState } from 'react';
import PagesHeader from "../components/PagesHeader";
import { Box, TextField, Button, Dialog, DialogContent, Typography } from '@mui/material';
import emailjs from 'emailjs-com'; 

function Feedback() {
  const [title, setTitle] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackSentDialogOpen, setFeedbackSentDialogOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_0j6vve2',
      'template_p9ubxwm',
      {
        to_name: 'Social Order Company',
        from_name: 'A user',
        title: title,
        message: feedbackText,
      },
      'XTXXwMmPcPJ1JAbp0' //--------------key--------------
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setTitle('');
      setFeedbackText('');
      setFeedbackSentDialogOpen(true); 
    }, (err) => {
      console.error('FAILED...', err);
      alert('Failed to send feedback. Please try again.');
    });
  }

  return (
    <Box sx={{ padding: '2%', mx: '3%', fontFamily: 'Raleway' }}>
      <PagesHeader txt="Feedback" />

      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: '2%' }}>
        <Box sx={{ marginBottom: '1%', textAlign: 'left', marginLeft: '1%', fontSize: '130%' }}>
          <label>Title:</label>
        </Box>
        <TextField
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Enter your title"
          required
          variant="outlined"
          fullWidth
          size="small"
          InputProps={{
            style: {
              backgroundColor: '#f7f7f7',
              borderColor: 'black',
              borderWidth: '1px',
              borderRadius: '5px'
            }
          }}
          sx={{ padding: '1%', fontSize: '16px', ml: '-0.8%' }}
        />

        <Box sx={{ marginBottom: '1%', textAlign: 'left', marginLeft: '1%', fontSize: '130%' }}>
          <label>Your Feedback:</label>
        </Box>
        <TextField
          value={feedbackText}
          onChange={e => setFeedbackText(e.target.value)}
          placeholder="Provide your feedback here..."
          required
          multiline
          rows={12}
          variant="outlined"
          fullWidth
          InputProps={{
            style: {
              backgroundColor: '#f7f7f7',
              borderColor: 'black',
              borderWidth: '1px',
              borderRadius: '5px'
            }
          }}
          sx={{ padding: '1%', fontSize: '19px', ml: '-0.8%' }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              height: '20px',
              width: '60px',
              backgroundColor: '#a2e0a1',
              borderRadius: '7px',
              padding: '0',
              fontSize: '14px',
              '&:hover': {
                backgroundColor: '#8fcf8e',
              }
            }}>
            Send
          </Button>
        </Box>
      </Box>

      <Dialog open={feedbackSentDialogOpen} onClick={() => { setFeedbackSentDialogOpen(false) }} sx={{}}>
        <DialogContent sx={{ backgroundColor: '#FFFEE0' }} onClick={() => { setFeedbackSentDialogOpen(false) }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontFamily: 'Raleway', fontSize: '18px' }}>
              Your feedback was sent to us!
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default Feedback;
