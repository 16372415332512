import React from 'react'
import PagesHeader from "../components/PagesHeader";
import { Typography } from '@mui/material';



function PrivacyPolicy(props) {
  return (
    <div > 
      <PagesHeader txt="Privacy-Policy" />
      <Typography
        sx={{
          fontFamily: "Poppins",
          color: "#F25B38",
          fontSize: "15px",
          width: "90%",
          margin: "0 auto",
          textAlign: "center",
          paddingTop: 2

        }} >
        This Privacy Policy describes how we collect, use
        and share your personal information when you use our app.
      </Typography>
      <Typography
        sx={{
          fontFamily: "Poppins",
          color: "black",
          fontSize: "15px",
          width: "80%",
          marginLeft: "10%",
          marginRight: '10%',
          textAlign: "left",
          paddingTop: 2
        }}
      >
        We collect the following personal information from you when you
        use our app:
        <br />
        <li>Your name and email address: We collect your name
          and email address when you create an account with our app.
        </li>
        <li>
          Your device information: We collect your device information,
          such as your device type, operating system, and IP address,
          when you use our app.
        </li>
        <li>
          Your usage data: We collect your usage data,
          such as the pages you visit and the actions you take on our app,
          when you use our app.
        </li>
        <br /><br />
        How We Use Your Personal Information
        We use your personal information for the following purposes:
        <li>
          To provide you with our app: We use your personal information
          to provide you with our app and to ensure that the app is
          functioning properly.
        </li>
        <li>
          To improve our app: We use your personal information to improve
          our app and to make it more user-friendly.
          To send you marketing messages: We may use your personal
          information to send you marketing messages about our products and services.
        </li>
        <li>
          To comply with the law: We may use your personal information to
          comply with the law, such as to respond to a subpoena or court
          order.
        </li>
        <br /><br />
        How We Share Your Personal Information
        We may share your personal information with the following third parties:
        Our service providers: We may share your personal information with our service providers, who help us to provide our app and to improve our app.
        Our marketing partners: We may share your personal information with our marketing partners, who help us to send you marketing messages about our products and services.
        Other third parties:
        We may share your personal information with other third parties, such as law enforcement agencies, if we are required to do so by law.
        Your Choices
        <br /><br />
        You have the following choices about your personal information:
        You can opt out of receiving marketing messages: You can opt out of receiving marketing messages by clicking on the "unsubscribe" link in any marketing message that we send you.
        You can delete your account: You can delete your account by contacting us at [email protected]
        Data Security
        We take steps to protect your personal information from unauthorized access, use, and disclosure. These steps include:
        We use Secure Sockets Layer (SSL) encryption to protect your personal information when it is transmitted over the internet.
        We store your personal information on secure servers.
        We restrict access to your personal information to authorized employees and contractors.
        Changes to This Privacy Policy
        We may update this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we will notify you by email or by posting a notice on our website.
        Contact Us
        <br /><br />
        If you have any questions about this Privacy Policy, please contact us at: 
        <br /><span style={{ color: 'blue' }}>socialordercompany@gmail.com</span>
        <br /><br />
      </Typography>
    </div>
  )
}

export default PrivacyPolicy