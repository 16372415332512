import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import { useRecoilState } from "recoil";
import { userProfileDataState } from '../services/atoms';
import { _friendsList } from '../services/atoms';
import userMap from '../services/userMap.json';


function ProfileAvatar(props) {
  const [friendsList, setFriendList] = useRecoilState(_friendsList);
  const [isClicked, setIsClicked] = useState(friendsList.includes(props.name));
  const [userProfileData, setUserProfileData] = useRecoilState(userProfileDataState);
  const [imageSrc, setImageSrc] = useState('');


  const proxyUrl = window.location.hostname === 'localhost' 
  ? 'http://192.168.1.14:8080/'  // Use the local network IP for localhost
  : 'https://9b67-77-137-182-173.ngrok-free.app/';  // Use ngrok URL for production

  useEffect(() => {
    const fullUrl = proxyUrl + props.img;

    // Force failure for testing fallback
    axios.get(fullUrl, { responseType: 'blob' })
    .then(response => {
        throw new Error('Forced failure for testing'); // Force an error to simulate a failure
    })
    .catch(e => {
        console.error('Simulated error or fetch failure:', e.message);
        const fallbackUrl = userMap[props.name]?.profile_pic_url || '/images/default.jpg';
        setImageSrc(fallbackUrl);
        console.log(`Using fallback image for ${props.name}:`, fallbackUrl);
    });
}, [props.img, proxyUrl, props.name]);

  // Effect for updating isClicked state when friendsList or props.name changes
  useEffect(() => {
    setIsClicked(friendsList.includes(props.name));
  }, [friendsList, props.name]);

  // Handle click events on the Avatar
  const handleClick = () => {
    const alreadyFriend = friendsList.includes(props.name);
    setIsClicked(!isClicked);
    setFriendList(prev => alreadyFriend ? prev.filter(name => name !== props.name) : [...prev, props.name]);
  };

  return (
    <div style={{
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: isClicked ? 'linear-gradient(45deg, #feda77, #f58529, #d62976, #962fbf, #4f5bd5)' : 'transparent',
        padding: '2px',
        boxSizing: 'border-box',
      }}>
      <Avatar
        alt={props.name}
        src={imageSrc}
        sx={{
          width: '60px',
          height: '60px',
          backgroundColor: 'transparent',
          border: '2px solid white',
          transition: 'border-color 0.4s ease',
        }}
        onClick={handleClick}
        onError={(e) => { e.target.onerror = null; 
          e.target.src = userMap[props.name]?.profile_pic_url || '/images/default.jpg';  }}
      />
    </div>
  );
}

export default ProfileAvatar;