import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import backgroundImage from "../images/background_login.png";
import Logo from "../images/Social_Order_Logo2.png";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  _loggedUser,
  _userFollowing,
  _unfollowedList,
  _muteStoryList,
  _mutePostList,
  _friendsList,
  CountActions,
  CountLaunch,
} from "../services/atoms";
import { useNavigate } from "react-router-dom";
import userMap from "../services/userMap.json";
import LoginForm from "../components/LoginForm";
// Styled component for the background box
const StyledBox = styled(Box)`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: 100%; /* Zoom out effect for web */
  background-position: center 25%; /* Move the image down */
  min-height: 100vh;
  overflow: hidden;
  flex-shrink: 0;

  @media (max-width: 768px) {
    background-size: cover; /* Adjust as needed */
    background-position: center;
  }
`;

const StyledImage = styled.img`
  width: 35%; /* Default width for web */
  max-width: 300px; /* Maximum width for large screens */
  // height: auto;
  margin-top: -1.5rem; /* Reduced padding for web */
  margin-bottom: 0rem;

  @media (max-width: 768px) {
    width: 80%; /* Adjusted width for mobile */
    max-width: 550px; /* Maximum width for mobile screens */
    margin-top: 2.5rem; /* Maintain original padding for mobile */
  }
`;

function Login() {
  const [showText, setShowText] = useState(false);
  const [logWInstagram, setLogWInstagram] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);
  const [username, setUsername] = useState("DemoUser");
  const [password, setPassword] = useState("Password");
  const [loggedUser, setLoggedUser] = useRecoilState(_loggedUser);
  const [userFollowing, setUserFollowing] = useRecoilState(_userFollowing);

  const [friendsList] = useRecoilState(_friendsList);
  const [unfollowedList] = useRecoilState(_unfollowedList);
  const [muteStoryList] = useRecoilState(_muteStoryList);
  const [mutePostList] = useRecoilState(_mutePostList);
  const [countActions, setCountActions] = useRecoilState(CountActions);
  const [countLaunch, setCountLaunch] = useRecoilState(CountLaunch);

  const resetUnfollowedList = useResetRecoilState(_unfollowedList);
  const resetMuteStoryList = useResetRecoilState(_muteStoryList);
  const resetMutePostList = useResetRecoilState(_mutePostList);

  const navigate = useNavigate();
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    setCountActions(0);
    setCountLaunch(0);
    resetUnfollowedList();
    resetMuteStoryList();
    resetMutePostList();

    console.log("After login list of unfollowedList:", unfollowedList);
    console.log("After login list of muteStoryList:", muteStoryList);
    console.log("After login list of mutePostList:", mutePostList);
    console.log("After login list of Selected:", friendsList);

    try {
      setServerResponse("Login Successful!"); // Show a success message
      setLoggedUser(username);
      fetchFollowingList();
      navigate("/home");
    } catch (error) {
      console.error("Error during login:", error.message);
      setServerResponse(error.message);
    } finally {
      setLoading(false);
    }
  };

  async function fetchFollowingList() {
    try {
      const followingList = Object.values(userMap);
      setUserFollowing(followingList);
      localStorage.setItem("storedFriends", JSON.stringify(followingList));
    } catch (error) {
      console.error("Fetching user following list failed:", error);
      setUserFollowing([]);
    }
  }

  return (
    <StyledBox>
      <Dialog
        open={logWInstagram}
        onClick={() => {
          setLogWInstagram(false);
        }}
        sx={{}}
      >
        <DialogContent
          sx={{ backgroundColor: "#FFFEE0" }}
          onClick={() => {
            setLogWInstagram(false);
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "Raleway", fontSize: "18px" }}>
              Currently, only the demo is available.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
      <StyledImage src={Logo} alt="Social Order Logo" />
      <LoginForm onLogin={handleLogin} />
      <Box sx={{ marginTop: "10px" }}>
        <Button
          variant="contained"
          onClick={handleLogin}
          sx={{
            borderRadius: "7px",
            background: "#64b5f6", // Brighter blue color
            color: "white", // Change text color to white
            border: "none", // Remove border
            fontFamily: "Raleway",
            textTransform: "none",
            padding: "4px 16px",
            width: "50%", // Adjust width to match second button
          }}
          disabled={loading}
        >
          Demo
        </Button>
      </Box>
      <Typography
        textAlign="center"
        sx={{
          color: "#000",
          fontFamily: "Raleway",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "30px",
          letterSpacing: "-0.017px",
          mt: 1,
          mb: 3,
        }}
      >
        Explore the App
      </Typography>
      <Typography
        textAlign="center"
        sx={{
          mt: -2,
          mb: -0.5,
          fontSize: "0.70em",
        }}
      >
        We are committed to assisting you in reducing social network usage,
        fostering mindful connections, and respecting your privacy.
        <br />
        Join us in embracing a purposeful and balanced online experience.
      </Typography>
      <IconButton
        onClick={() => setShowText(!showText)}
        sx={{
          width: 15,
          height: 15,
          border: "1.5px solid black",
          backgroundColor: "transparent",
          borderRadius: "50%",
          mt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          "&:hover": {
            backgroundColor: "transparent",
            border: "1.5px solid black",
          },
        }}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 8 10"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <circle cx="4" cy="1.5" r="0.9" fill="black" />
          <rect x="3.5" y="3" width="1" height="5" fill="black" />
        </svg>
      </IconButton>
      {showText && (
        <Box
          sx={{
            width: "90%",
            height: "20vh",
            flexShrink: 0,
            borderRadius: 5,
            background: "#FFFEE0",
            position: "absolute",
            top: "70%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 3,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            zIndex: 2,
            overflow: "auto",
          }}
        >
          <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
            Our user-friendly platform is dedicated to fostering a healthier
            relationship with technology and social interaction.
            <br />
            We're committed to curbing the excessive use of social networks,
            striving to alleviate addiction and promote a more mindful digital
            lifestyle.
            <br />
            <br />
            Rest assured, your privacy is our utmost concern.
            <br />
            We stand by our promise to never access your Instagram page without
            your explicit consent.
            <br />
            Every action we take is transparent, with your full awareness and
            understanding.
            <br />
            Your trust is paramount, and we vow to never share your personal
            information with any external entity.
            <br />
            Your data remains exclusively yours.
            <br />
            <br />
            Join us in embracing a purposeful and balanced online experience.
            <br />
            Let's pave the way towards a future where technology empowers
            without overwhelming,
            <br />
            and connections flourish without consuming.
            <br />
            Together, we'll redefine the way we interact with the digital world.
          </Typography>

          <IconButton
            sx={{
              backgroundColor: "grey",
              color: "black",
              width: 20,
              height: 20,
              alignSelf: "center",
              marginTop: 2,
              borderRadius: "50%",
            }}
            onClick={() => setShowText(false)}
          >
            X
          </IconButton>
        </Box>
      )}
      {(showText || logWInstagram) && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 1,
          }}
          onClick={() => {
            setShowText(false);
          }}
        ></Box>
      )}
      {isBurgerMenuOpen && (
        <div
          style={{
            position: "absolute",
            top: "20px", // Adjust the top position as needed
            right: "20px", // Adjust the right position as needed
            backgroundColor: "white",
            border: "1px solid black",
            padding: "10px",
            zIndex: 1000, // Ensure it appears above other content
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "30px", // Adjust the top position as needed
              right: "15px", // Adjust the right position as needed
              width: "0",
              height: "0",
              borderTop: "10px solid transparent",
              borderBottom: "10px solid transparent",
              borderRight: "10px solid black", // Arrow pointing to the section
            }}
          />
          <div>{/* ... (other menu items) */}</div>
        </div>
      )}
    </StyledBox>
  );
}

export default Login;
