import { atom, selector } from "recoil";

export const _friendsList = atom({
  key: "_friendsList",
  default: [],
});

export const actionHistory = atom({
  key: "actionHistory",
  default: null,
});

export const _userFollowing = atom({
  key: "_userFollowing",
  default: [],
});

export const _loggedUser = atom({
  key: "_loggedUser",
  default: "",
});

export const dialogOpenCountState = atom({
  key: "dialogOpenCountState", // unique ID (with respect to other atoms/selectors)
  default: 0,
});

export const CountActions = atom({
  key: "CountActions",
  default: 0,
});

export const CountLaunch = atom({
  key: "CountLaunch",
  default: 0,
});

export const _muteStoryList = atom({
  key: "_muteStoryList",
  default: [],
});

export const _mutePostList = atom({
  key: "_mutePostList",
  default: [],
});

export const selectAllState = atom({
  key: "selectAllState",
  default: false,
});

export const selectedTimeAtom = atom({
  key: "selectedTimeAtom",
  default: { hour: "03", minute: "00" },
});

export const userFollowingAtom = atom({
  key: "userFollowingAtom",
  default: [],
});

export const userProfileDataState = atom({
  key: "userProfileDataState", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const userListAtom = atom({
  key: "userList",
  default: [], // default value is an empty array
});

export const _unfollowedList = atom({
  key: "_unfollowedList",
  default: [],
});

// Selector to get user IDs from usernames in unfollowedList
export const userIdsFromUnfollowedListSelector = selector({
  key: "userIdsFromUnfollowedListSelector",
  get: ({ get }) => {
    const userList = get(userListAtom);
    const unfollowedList = get(_unfollowedList);

    // Map unfollowedList to their corresponding user IDs by finding each username in the userList
    return unfollowedList
      .map((username) => {
        const user = userList.find((user) => user.username === username);
        return user ? user.userID : null; // Return userID if user is found, otherwise return null
      })
      .filter((userID) => userID !== null); // Filter out any null values (i.e., usernames not found)
  },
});

// Selector to get user IDs from usernames in muteStoryList
export const userIdsFromMuteStoryListSelector = selector({
  key: "userIdsFromMuteStoryListSelector",
  get: ({ get }) => {
    const userList = get(userListAtom);
    const muteStoryList = get(_muteStoryList);

    return muteStoryList
      .map((username) => {
        const user = userList.find((user) => user.username === username);
        return user ? user.userID : null; // Return userID if user is found, otherwise return null
      })
      .filter((userID) => userID !== null); // Filter out any null values
  },
});

// Selector to get user IDs from usernames in mutePostList
export const userIdsFromMutePostListSelector = selector({
  key: "userIdsFromMutePostListSelector",
  get: ({ get }) => {
    const userList = get(userListAtom);
    const mutePostList = get(_mutePostList);

    return mutePostList
      .map((username) => {
        const user = userList.find((user) => user.username === username);
        return user ? user.userID : null; // Return userID if user is found, otherwise return null
      })
      .filter((userID) => userID !== null); // Filter out any null values
  },
});
