import * as React from 'react';
import Chip from '@mui/material/Chip';
import { useRecoilState } from "recoil";
import { _friendsList } from "../services/atoms";

function NameChip(props) {

    const [friendsList, setFriendList] = useRecoilState(_friendsList)

    const handleDelete = () => {
        if (friendsList.includes(props.name)) {
            const updatedFriendsList = friendsList.filter(name => name !== props.name);
            setFriendList(updatedFriendsList);
        }
    };
    

    return (
        <Chip label={props.name}
            variant='outlined'
            onDelete={handleDelete}
            sx={{
                fontFamily: "Abel",
                fontSize: "10px", // Adjust the font size in pixels
                height: "25px", // Adjust the height to a smaller value
                ml: "2px", // Add a small left margin to give space between adjacent chips
                mr: "4px", // Add a small right margin to give space between adjacent chips
                pl: "0px", // Adjust padding to suit the smaller size
                
                '& .MuiChip-deleteIcon': { // this targets the delete icon within the Chip
                    marginLeft: '2px',  // Adjust as per your needs
                    marginRight: '3px',  // Adjust as per your needs
                    // ... add other styles here ...
                }
            }} 
        />
    )
    
}

export default NameChip