import React from 'react';
import { Chip } from '@mui/material';

function Tag({ label, onSelect, selected }) {
  const handleClick = () => {
    onSelect(label);
  };

  return (
    <Chip
      label={label}
      onClick={handleClick}
      color="default"
      variant={selected ? "filled" : "outlined"}
      sx={{
        backgroundColor: "transparent !important",  // Set background color to transparent
        color: selected ? "#F99A80 !important" : "#333 !important",  // Adjust text color as needed
        marginRight: 0,  // Adjust margin as needed
        marginBottom: 0.5,  // Adjust margin as needed
        marginTop: -1,  // Adjust top margin to a smaller value
        border: "none !important",  // Remove border
        borderRadius: "8px !important",  // Set border radius as needed
        height: "28px !important",  // Set height as needed
        fontSize: "14px !important",  // Set font size as needed
        padding: "0 4px !important",  // Set padding as needed
      }}
    />
  );
}

export default Tag;
