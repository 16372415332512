import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  TextField,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";

function LoginForm({ onLogin }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [serverResponse, setServerResponse] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleLogin = async () => {
    setTimeout(() => {
      setLoading(false);
      setShowLogin(true); // Show the popup message
      setTimeout(() => {
        setShowLogin(false); // Hide the popup after 3 seconds
      }, 3000);
    }, 1000);

    // console.log("Login button clicked");
    // setLoading(true); // Start showing the circular progress indicator
    // try {
    //   const response = await axios.post(
    //     "https://b712-2a06-c701-40fc-e800-c09a-bca6-a58-6291.ngrok-free.app/login",
    //     {
    //       userId: "61682684871",
    //       username: username,
    //       password: password,
    //     }
    //   );
    //   if (response.status === 200) {
    //     onLogin(username, password);
    //     setTimeout(() => {
    //       navigate("/home");
    //     });
    //   } else {
    //     console.error("Login failed:", response.data);
    //   }
    //   setServerResponse(response.data);
    //   // setOpenDialog(true);
    // } catch (error) {
    //   console.error("Error during login:", error.message);
    // } finally {
    //   setLoading(false); // Hide the circular progress indicator
    // }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ maxWidth: 300, mt: 2 }}>
        {/* <TextField
          label="User ID"
          variant="outlined"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          margin="normal"
          fullWidth
          sx={{ mt: 4 }}
        /> */}
        <TextField
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          margin="normal"
          fullWidth
          sx={{ mt: -2 }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          fullWidth
          sx={{ mt: 0 }}
        />

        <Box sx={{ textAlign: "center" }}>
          {showLogin && (
            <Typography sx={{ fontFamily: "Raleway", fontSize: "18px" }}>
              Currently, only the demo is available.
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, width: "50%" }}
            disabled={loading}
            onClick={handleLogin}
          >
            Login
          </Button>
        </Box>

        <Typography
          textAlign="center"
          sx={{
            color: "#000",
            fontFamily: "Raleway",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "30px",
            letterSpacing: "-0.017px",
            mt: 2,
          }}
        >
          Please enter your Instagram credentials to sign in
        </Typography>
      </Box>

      {/* Popup for displaying demo message */}
      <Dialog
        open={showLogin}
        onClose={() => setShowLogin(false)}
        sx={{ zIndex: 2000 }}
      >
        <DialogContent sx={{ backgroundColor: "#FFFEE0", textAlign: "center" }}>
          <Typography sx={{ fontFamily: "Raleway", fontSize: "18px" }}>
            Currently, only the demo is available.
          </Typography>
        </DialogContent>
      </Dialog>
      {/* Dialog for displaying server response */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Server Response</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{serverResponse}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default LoginForm;
