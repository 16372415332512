import React from 'react'

function ErrorPage() {
    return (
        <div>
            <h1>
                Error 404
            </h1>
        </div>
    )
}

export default ErrorPage