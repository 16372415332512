import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, Box, Typography } from '@mui/material'; // <-- Added DialogContentText import
import { useSetRecoilState } from 'recoil';
import { _unfollowedList, _muteStoryList, _mutePostList } from "../services/atoms";

function CancelLastButton({ actionHistory,  setActionHistory, }) { // <-- Added new props
    const setUnfollowedList = useSetRecoilState(_unfollowedList);
    const setMuteStoryList = useSetRecoilState(_muteStoryList);
    const setMutePostList = useSetRecoilState(_mutePostList);
    const [noActionToCancel, setNoActionToCancel] = useState(false);
    const [showCancelActionDialog, setShowCancelActionDialog] = useState(false); // <-- New state for the new dialog

    const YellowGradient = "linear-gradient(#FFD700 0%, #FFFBAA 100%)"; // Adjusted Yellow shades to be brighter


    const handleClick = (event) => {
        if (actionHistory) {
            setShowCancelActionDialog(true);
            console.log('Canceling last action on users:', actionHistory.targetUsernames); // New log statement for cancel action
            console.log('Cancel action being taken:', actionHistory.oppositeAction); // New log statement for cancel action

            const actionEndpoint = actionHistory.action;
            const selectedUsernamesArray = actionHistory.targetUsernames.split(',');

            if (actionEndpoint === "unfollow_users") {
                setUnfollowedList(prevState => prevState.filter(username => !selectedUsernamesArray.includes(username)));
            } else if (actionEndpoint === "mute_stories") {
                setMuteStoryList(prevState => prevState.filter(username => !selectedUsernamesArray.includes(username)));
            } else if (actionEndpoint === "mute_posts") {
                setMutePostList(prevState => prevState.filter(username => !selectedUsernamesArray.includes(username)));
            }
            setActionHistory(null);
            setTimeout(() => {  
                handleClose();  
            }, 2000);  
        } else {
            console.warn('No actions to cancel');
            setNoActionToCancel(true);
        }
    };

    const handleClose = () => { // <-- Added handleClose function
        setShowCancelActionDialog(false);
    };

    return (
        <Box sx={{ display: 'flex', gap: 1, ml: 'auto', alignItems: 'center', mb: 2 }}>
<Button
    variant="contained"
    size="small"
    onClick={handleClick}
    sx={{
        height: '20px',
        py: 0.2,
        fontSize: '0.6rem',
        borderRadius: '5px',
        border: '1px solid black',
        background: '#333',  // Dark gray background
        color: 'white',  // White text
        '&:hover, &:active, &:focus': {
            background: '#444',  // Slightly lighter gray on hover, active, and focus
        },
    }}
>
    Cancel Last Action
</Button>


            <Dialog open={noActionToCancel} onClick={() => { setNoActionToCancel(false) }} sx={{}}>
                <DialogContent sx={{ backgroundColor: '#FFFEE0', }} onClick={() => { setNoActionToCancel(false) }}>
                    <Box sx={{ textAlign: 'center', }}>
                        <Typography sx={{ fontFamily: 'Raleway', fontSize: '18px', }} >
                            No action to cancel
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                open={showCancelActionDialog}
                onClose={handleClose}
                sx={{
                    '.MuiDialog-paper': {
                        background: YellowGradient,  // Applying gradient background to the paper component of the Dialog
                        borderRadius: '12px', // Moved borderRadius here to affect the background color at the corners too
                        width: '80%', // Setting width to make the dialog bigger
                        height: '15%', // Setting height to make the dialog bigger
                    },
                }}
            >
                <DialogContent sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: '10px', // Added padding to give some space inside the dialog
                }}>
                    <DialogContentText sx={{ fontFamily: "Inter", }}>
                        We are cancelling your last action!
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default CancelLastButton;
