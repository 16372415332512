import React from 'react'
import { Typography } from '@mui/material'


function PagesHeader(props) {
  return (
    <Typography variant="h4"
    sx={{
      fontFamily:'Bad Script',
      color:"#F25B38"
    }}>
      {props.txt}
    </Typography>
    )
}

export default PagesHeader