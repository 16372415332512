import React from 'react'

function Registration() {
  return (
    <div>
      <h1>
        Registration
      </h1>
    </div>
  )
}

export default Registration