import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  CircularProgress,
  Modal,
  Box,
  Typography,
  Button,
} from "@mui/material";
import PagesHeader from "../components/PagesHeader";
import FriendsListForHistory from "../components/FriendsListForHistory";
import TimeSettingComponent from "../components/TimeSettingComponent";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  _unfollowedList,
  _muteStoryList,
  _mutePostList,
  selectedTimeAtom,
  userListAtom,
  userIdsFromUnfollowedListSelector,
  userIdsFromMuteStoryListSelector,
  userIdsFromMutePostListSelector,
  CountLaunch,
} from "../services/atoms";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DynamicArrow from "../components/DynamicArrow"; // Adjust path if needed

function HistoryActions() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false); // State to manage the success dialog visibility
  const unfollowedList = useRecoilValue(_unfollowedList);
  const [serverResponse, setServerResponse] = useState(null);
  const setUnfollowedList = useSetRecoilState(_unfollowedList);
  const setMuteStoryList = useSetRecoilState(_muteStoryList);
  const setMutePostList = useSetRecoilState(_mutePostList);
  const muteStoryList = useRecoilValue(_muteStoryList);
  const mutePostList = useRecoilValue(_mutePostList);
  const userList = useRecoilValue(userListAtom);
  const userIds = useRecoilValue(userIdsFromUnfollowedListSelector);
  const userIdsMuteStory = useRecoilValue(userIdsFromMuteStoryListSelector);
  const userIdsMutePost = useRecoilValue(userIdsFromMutePostListSelector);
  const [countLaunch, setCountLaunch] = useRecoilState(CountLaunch);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false); // State to manage the information dialog visibility
  const handleUnfollow = useCallback(async () => {
    try {
      const response = await axios.post(
        "https://b712-2a06-c701-40fc-e800-c09a-bca6-a58-6291.ngrok-free.app/Actions/Unfollow-user",
        {
          userId: "61682684871", // Ensure this is dynamically set if needed
          usersToUnfollow: userIds,
        }
      );
      if (response.status === 200) {
        console.log("Unfollow successful:", response.data);
        setUnfollowedList([]);
        return true; // Indicate success
      } else {
        console.error("Unfollow failed:", response.data);
        return false; // Indicate failure
      }
    } catch (error) {
      console.error("Error during unfollow:", error);
      return false; // Indicate failure
    }
  }, [userIds, setUnfollowedList]); // Dependencies

  const handleMuteStory = async () => {
    try {
      const response = await axios.post(
        "https://b712-2a06-c701-40fc-e800-c09a-bca6-a58-6291.ngrok-free.app/actions/mute",
        {
          userId: "61682684871", // Replace 'YourUserID' with actual user ID
          usersToMute: userIdsMuteStory,
          actionType: "stories",
        }
      );
      if (response.status === 200) {
        console.log("Mute stories successful:", response.data);
        setMuteStoryList([]); // Optionally clear list after successful operation
        return true; // Indicate success
      } else {
        console.error("Mute stories failed:", response.data);
        return false; // Indicate failure
      }
    } catch (error) {
      console.error("Error during mute stories:", error);
      return false; // Indicate failure
    }
  };

  const handleMutePost = async () => {
    try {
      const response = await axios.post(
        "https://b712-2a06-c701-40fc-e800-c09a-bca6-a58-6291.ngrok-free.app/actions/mute",
        {
          userId: "61682684871", // Replace 'YourUserID' with actual user ID
          usersToMute: userIdsMutePost,
          actionType: "posts",
        }
      );
      if (response.status === 200) {
        console.log("Mute posts successful:", response.data);
        setMutePostList([]); // Optionally clear list after successful operation
        return true; // Indicate success
      } else {
        console.error("Mute posts failed:", response.data);
        return false; // Indicate failure
      }
    } catch (error) {
      console.error("Error during mute posts:", error);
      return false; // Indicate failure
    }
  };

  useEffect(() => {
    console.log("userIds:", userIds);
  }, [userIds]);

  const [selectedTime, setSelectedTime] = useRecoilState(selectedTimeAtom) || {
    hour: "03",
    minute: "00",
  };
  const [selectedTab, setSelectedTab] = useState("muteStories"); // Set default tab to 'muteStories'

  useEffect(() => {
    setInfoDialogOpen(true);
    const timer = setTimeout(() => {
      setInfoDialogOpen(false);
    }, 5000); // Dialog will close after 5 seconds
    return () => clearTimeout(timer); // Cleanup the timer when the component unmounts or the dialog manually closes
  }, []);

  const handleCloseInfoDialog = () => {
    setInfoDialogOpen(false);
  };

  const historyList = [
    ...new Set([...unfollowedList, ...muteStoryList, ...mutePostList]),
  ];
  const friendsToShow = historyList.map((username) => {
    const unfollowedUser = unfollowedList.find(
      (user) => user.username === username
    );
    const mutedStoryUser = muteStoryList.find(
      (user) => user.username === username
    );
    const mutedPostUser = mutePostList.find(
      (user) => user.username === username
    );

    return {
      username,
      photoURL:
        unfollowedUser?.photoURL ||
        mutedStoryUser?.photoURL ||
        mutedPostUser?.photoURL ||
        "",
    };
  });

  const [isBottomScrolled, setIsBottomScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setIsBottomScrolled(true);
      } else {
        setIsBottomScrolled(false);
      }
    }
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const tabStyle = (isSelected) => ({
    display: "flex",
    width: "90px",
    height: "20px",
    padding: "10px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    flexShrink: 0,
    borderRadius: "8px",
    border: isSelected ? "1px solid #F25B38" : "1px solid #000",
    background: "#F7F7F7",
    boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.25)",
    textTransform: "none",
    color: "#000",
    fontSize: "10px",
  });

  useEffect(() => {
    if (!selectedTime) {
      setSelectedTime({ hour: "03", minute: "00" });
    }
  }, [selectedTime, setSelectedTime]);

  const [actionsPerformed, setActionsPerformed] = useState(false);

  useEffect(() => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    const selectedHour = Number(selectedTime.hour);
    const selectedMinute = Number(selectedTime.minute);

    if (
      selectedHour === currentHour &&
      selectedMinute === currentMinute &&
      !actionsPerformed
    ) {
      console.log("Time match found, triggering actions.");
      handlePerformActions();
      setActionsPerformed(true); // Set flag to true after actions are triggered
    } else if (
      selectedHour !== currentHour ||
      selectedMinute !== currentMinute
    ) {
      setActionsPerformed(false); // Reset flag when time changes
    }
  }, [selectedTime, actionsPerformed]);

  const timeOptions = useMemo(() => {
    const baseOptions = [
      ["00", "05"],
      ["01", "10"], // ... (add all your options here)
      ["23", "55"],
    ];
    return [...baseOptions, ...baseOptions, ...baseOptions];
  }, []);

  useEffect(() => {
    const box = document.getElementById("time-options-box");
    if (box) {
      box.addEventListener("scroll", () => {
        if (box.scrollTop < 25) {
          box.scrollTop = (25 * timeOptions.length) / 3;
        } else if (box.scrollTop > (25 * timeOptions.length) / 3) {
          box.scrollTop = 25;
        }
      });
    }
  }, [timeOptions]);

  const handlePopup = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePerformActions = async () => {
    setLoading(true); // Show loading indicator

    // Perform actions and get results
    const unfollowResult = await handleUnfollow();
    const muteStoryResult = await handleMuteStory();
    const mutePostResult = await handleMutePost();

    setLoading(false); // Hide loading indicator

    // Check if any action was successful
    if (unfollowResult || muteStoryResult || mutePostResult) {
      setSuccessDialogOpen(true); // Show success dialog
    }
    setOpen(false); // Close TimeSettingComponent
  };

  // const handlePerformActions = async () => {
  //     setLoading(true); // Show loading indicator

  //     // Simulate successful responses
  //     const unfollowResult = true; // Simulate unfollow success
  //     const muteStoryResult = true; // Simulate mute story success
  //     const mutePostResult = true; // Simulate mute post success

  //     // Simulate delay
  //     await new Promise(resolve => setTimeout(resolve, 2000));

  //     setLoading(false); // Hide loading indicator

  //     // Check if any action was successful
  //     if (unfollowResult || muteStoryResult || mutePostResult) {
  //         setSuccessDialogOpen(true); // Show success dialog
  //     }
  //     setOpen(false); // Close TimeSettingComponent
  // };

  return (
    <div>
      {/* Sticky Top Section */}
      <div
        style={{
          position: "sticky",
          top: isBottomScrolled ? "80px" : "0",
          zIndex: 10,
          background: "white",
        }}
      >
        <PagesHeader txt="Your Actions" />
        <Typography
          style={{
            color: "#000",
            textAlign: "center",
            fontFamily: "Raleway",
            fontSize: "12px",
            fontStyle: "italic",
            fontWeight: 400,
            lineHeight: "9px",
            letterSpacing: "-0.148px",
            marginBottom: "30px",
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <>
            All actions in this tab will post to your <br />
            <br />
            Instagram at
          </>
          &nbsp;
          <span
            style={{
              color: "#0000FF",
              cursor: "pointer",
              fontSize: "18px",
              verticalAlign: "middle",
            }}
            onClick={() => handlePopup(selectedTime)}
          >
            {selectedTime?.hour}:{selectedTime?.minute}
          </span>
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            style={tabStyle(selectedTab === "muteStories")}
            onClick={() => setSelectedTab("muteStories")}
          >
            Mute Stories
          </Button>
          <Button
            variant="contained"
            style={tabStyle(selectedTab === "mutePosts")}
            onClick={() => setSelectedTab("mutePosts")}
          >
            Mute Posts
          </Button>
          <Button
            variant="contained"
            style={tabStyle(selectedTab === "unfollow")}
            onClick={() => setSelectedTab("unfollow")}
          >
            Unfollow
          </Button>
        </Box>
      </div>
      {countLaunch <= 1 && (
        <Dialog
          open={infoDialogOpen}
          onClose={handleCloseInfoDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              background: "linear-gradient(#72DC8F 0%, #CFFFC2)", // Specify the desired background color
              borderRadius: "12px",
              textAlign: "center",
            },
          }}
        >
          <DialogContent>
            <Typography>
              All actions in this tab will post to your Instagram at{" "}
              {selectedTime.hour}:{selectedTime.minute}.<br />
              <br /> Press here to change
            </Typography>
            <Box sx={{ position: "absolute", top: "10%", right: "10%" }}>
              {/* Adjust positioning as needed */}
              <DynamicArrow top="5%" right="5%" direction="right" />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {/* Loading Indicator Dialog */}
      <Dialog open={loading}>
        <DialogContent sx={{ backgroundColor: "#FFE0C6" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography mt={2}>We are executing your actions...</Typography>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        PaperProps={{
          sx: {
            background: "linear-gradient(#FFD700 0%, #FFFBAA 100%)", // Specify the desired background color
            borderRadius: "12px",
            textAlign: "center",
          },
        }}
      >
        <DialogContent>
          <DialogContentText sx={{ fontFamily: "Inter" }}>
            All actions have been successfully posted to your Instagram account!
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Modal Section */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <TimeSettingComponent
            onClick={handlePerformActions}
            open={open}
            handleClose={handleClose}
          />
        </div>
      </Modal>

      {/* Conditional rendering for the list */}
      {{
        muteStories: muteStoryList,
        mutePosts: mutePostList,
        unfollow: unfollowedList,
      }[selectedTab].length === 0 ? (
        <Typography
          style={{
            color: "#000",
            textAlign: "center",
            fontFamily: "Raleway",
            fontSize: "16px",
            fontStyle: "italic",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "-0.148px",
            marginTop: "40px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          No friends to show here yet, visit the Home screen to perform actions.
        </Typography>
      ) : (
        <div style={{ height: "500px", overflowY: "auto" }}>
          <FriendsListForHistory
            selectedTab={selectedTab}
            friendsList={friendsToShow}
            sx={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  );
}

export default HistoryActions;
