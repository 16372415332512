import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useRecoilState } from "recoil";
import {
  _friendsList,
  dialogOpenCountState,
  CountActions,
} from "../services/atoms";
import AnimatedArrow from "./AnimatedArrow"; // adjust the path as necessary

export default function AlertDialog(props) {
  const [friendsList, setFriendsList] = useRecoilState(_friendsList);
  const [showContent, setShowContent] = React.useState(true);
  // const [openCount, setOpenCount] = React.useState(0); // State to keep track of open counts
  const [openCount, setOpenCount] = useRecoilState(dialogOpenCountState);
  const [countActions, setCountActions] = useRecoilState(CountActions);

  React.useEffect(() => {
    if (props.open) {
      setOpenCount((prevCount) => {
        const newCount = prevCount;
        const displayTime = countActions < 3 ? 6000 : 3000;
        console.log("openCount" + openCount);

        const timer = setTimeout(() => {
          props.setOpen(false);
        }, displayTime);

        return newCount;
      });
      setShowContent(true); // Show content when the dialog is opened
    } else {
      setShowContent(false); // Hide content when the dialog is about to close
      setFriendsList([]); // Reset the friendsList to an empty array when the dialog fully closes
    }
  }, [props.open]);

  return (
    <div>
      <Dialog
        open={props.open}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            background: props.themeColor,
          },
        }}
        transitionDuration={0}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {showContent &&
            (friendsList.length > 0 ? (
              <DialogContentText sx={{ fontFamily: "Inter" }}>
                <span style={{ marginRight: "5px" }}>
                  You have chosen to {props.txt} from
                </span>
                {friendsList.length} of your friends <br />
                <br />
                All your actions are shown in the middle tab below!
                {props.open && openCount < 3 && countActions < 4 && (
                  <AnimatedArrow />
                )}{" "}
                {/* Show arrow only for the first two times */}
              </DialogContentText>
            ) : (
              <DialogContentText sx={{ fontFamily: "Inter" }}>
                You need to choose Friends to be able to {props.txt}
              </DialogContentText>
            ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}
