import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import SearchBar from "../components/SearchBar";
import FriendsContainer from "../components/FriendsContainer";
import NameChipStack from "../components/NameChipStack";
import BaseButton from "../components/BaseButton";
import CancelLastButton from "../components/CancelLastButton";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  _userFollowing,
  actionHistory,
  _friendsList,
  _unfollowedList,
  _muteStoryList,
  _mutePostList,
  userListAtom,
  dialogOpenCountState,
} from "../services/atoms";
import TagStack from "../components/TagStack"; // Import the TagStack component
import userMap from "../services/userMap.json";
import axios from "axios";

function Home() {
  const userId = "61682684871"; // Hardcoded user ID
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false); // To track if request is being processed
  const [serverResponse, setServerResponse] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTag, setCurrentTag] = useState("Everyone");
  const [actionHistoryState, setActionHistory] = useRecoilState(actionHistory); // <-- Use the actionHistory atom
  const [friendsList, setFriendList] = useRecoilState(_friendsList);
  const [unfollowedList, setUnfollowedList] = useRecoilState(_unfollowedList);
  const [muteStoryList, setMuteStoryList] = useRecoilState(_muteStoryList);
  const [mutePostList, setMutePostList] = useRecoilState(_mutePostList);
  const [openCount, setOpenCount] = useRecoilState(dialogOpenCountState);

  // const [followingFriends, setFollowingFriends] = useState([]);
  const [followingFriends, setFollowingFriends] = useState(
    JSON.parse(localStorage.getItem("storedFriends")) || []
  );
  // const followingFriends = useState(JSON.parse(localStorage.getItem('storedFriends')) || [])

  const [friendsToShow, setFriendsToShow] = useState(followingFriends[0]);
  const setUserList = useSetRecoilState(userListAtom);

  useEffect(() => {
    // Reset the open count to 0 when this component mounts
    setOpenCount(0);
  }, [setOpenCount]); // Dependency array includes the setter function

  useEffect(() => {
    console.log("Updated list of unfollowedList:", unfollowedList);
  }, [unfollowedList]);

  useEffect(() => {
    console.log("Updated list of muteStoryList:", muteStoryList);
  }, [muteStoryList]);

  useEffect(() => {
    console.log("Updated list of mutePostList:", mutePostList);
  }, [mutePostList]);

  useEffect(() => {
    console.log("Updated list of friendsList:", friendsList);
  }, [friendsList]);

  // Example tags list. Adjust as needed.
  // const tags = ["everyone", "not following me", "last year follow", "popular"];

  const handleTextInput = (e) => {
    setSearchInput(e.target.value);
  };

  const sendActionRequest = async (actionEndpoint) => {
    console.log("sendActionRequest triggered with endpoint:", actionEndpoint);

    const selectedUsernames = friendsList.join(",");

    if (!selectedUsernames) {
      console.warn("No username selected. Aborting action.");
      return;
    }

    // Increment the open count here
    setOpenCount((prev) => prev + 1);

    try {
      const selectedUsernames = friendsList.join(",");
      const oppositeActions = {
        mute_stories: "unmute_stories",
        mute_posts: "unmute_posts",
        unfollow_users: "follow_users",
      };

      const selectedUsernamesArray = selectedUsernames.split(",");

      if (actionEndpoint === "unfollow_users") {
        setUnfollowedList((prevState) => [
          ...prevState,
          ...selectedUsernamesArray,
        ]);
      } else if (actionEndpoint === "mute_stories") {
        setMuteStoryList((prevState) => [
          ...prevState,
          ...selectedUsernamesArray,
        ]);
      } else if (actionEndpoint === "mute_posts") {
        setMutePostList((prevState) => [
          ...prevState,
          ...selectedUsernamesArray,
        ]);
      }
      setActionHistory({
        action: actionEndpoint,
        oppositeAction: oppositeActions[actionEndpoint],
        targetUsernames: selectedUsernames,
      });
    } catch (error) {
      console.error("Error during action:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://b712-2a06-c701-40fc-e800-c09a-bca6-a58-6291.ngrok-free.app/Tags/ComprehensiveFollowers",
          {
            userId: "61682684871",
          }
        );

        if (response.status === 200) {
          const newData = response.data.map((user) => {
            const localUserData = userMap[user.username];
            return {
              profile_pic_url:
                user.profile_pic_url || localUserData?.profile_pic_url, // Use local URL if API URL is missing
              username: user.username,
              not_following_me_back: user.not_following_me_back,
              is_highly_active_user: user.is_highly_active_user,
              pk: user.user_ID,
            };
          });

          localStorage.setItem("storedFriends", JSON.stringify(newData));

          const userList = response.data.map((user) => ({
            username: user.username,
            userID: user.user_ID,
          }));
          setUserList(userList);
          setServerResponse(response.data);
          setOpenDialog(true);

          setFollowingFriends(newData);
          setFriendsToShow(newData);
        } else {
          throw new Error("Request failed");
        }
      } catch (error) {
        console.error("Error during request:", error.message);
        // Fallback to using userMap data directly
        const newData = Object.values(userMap).map((user) => ({
          profile_pic_url: user.profile_pic_url,
          username: user.username,
          not_following_me_back: user.not_following_me_back,
          is_highly_active_user: user.is_highly_active_user,
          pk: user.user_ID,
        }));

        localStorage.setItem("storedFriends", JSON.stringify(newData));

        const userList = Object.values(userMap).map((user) => ({
          username: user.username,
          userID: user.user_ID,
        }));
        setUserList(userList);
        setFollowingFriends(newData);
        setFriendsToShow(newData);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setUserList]);

  const tags = ["Everyone", "Not Following Me Back", "Highly Active Users"];

  const handleTagSelect = (label) => {
    let filteredFriends;
    setCurrentTag(label);
    if (label === "Everyone") {
      filteredFriends = followingFriends.filter((friend) =>
        friend.username.toLowerCase().includes(searchInput.toLowerCase())
      );
    } else if (label === "Not Following Me Back") {
      filteredFriends = followingFriends
        .filter((friend) =>
          friend.username.toLowerCase().includes(searchInput.toLowerCase())
        )
        .filter((friend) => friend.not_following_me_back);
    } else if (label === "Highly Active Users") {
      filteredFriends = followingFriends
        .filter((friend) =>
          friend.username.toLowerCase().includes(searchInput.toLowerCase())
        )
        .filter((friend) => userMap[friend.username]?.is_highly_active_user);
    }
    setFriendsToShow(filteredFriends);
  };

  useEffect(() => {
    handleTagSelect(currentTag);
  }, [searchInput, currentTag, followingFriends]);

  return (
    <Box sx={{ height: "74vh" }}>
      <Box sx={{ mb: "2%" }}>
        <SearchBar handleTextInput={handleTextInput} />
      </Box>
      <Box sx={{ ml: -0.5 }}>
        <TagStack
          tagList={tags}
          onSelectTag={handleTagSelect}
          defaultSelectedTag={currentTag}
        />
      </Box>
      <Box>
        <Dialog open={loading}>
          <DialogContent sx={{ backgroundColor: "#FFE0C6" }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress />
              <Typography mt={2}>We are loading your friends...</Typography>
            </Box>
          </DialogContent>
        </Dialog>

        <Box
          position="relative"
          width="100%"
          sx={{ maxHeight: "60vh", overflowY: "auto" }}
        >
          <FriendsContainer
            friendsList={friendsToShow}
            sx={{ width: "100%" }}
          />
          <Box position="absolute" top={8} right={5}>
            <CancelLastButton
              sendActionRequest={sendActionRequest}
              actionHistory={actionHistoryState}
              setActionHistory={setActionHistory}
            />
          </Box>
        </Box>
        <Box ml={-1}>
          <NameChipStack sx={{ height: "auto", px: 1 }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            ml: 1,
          }}
        >
          <BaseButton
            txt="Mute Stories"
            color="V1"
            onClick={() => sendActionRequest("mute_stories")}
          />
          <BaseButton
            txt="Mute Posts"
            color="V2"
            onClick={() => sendActionRequest("mute_posts")}
          />
          <BaseButton
            txt="Unfollow"
            color="V4"
            onClick={() => sendActionRequest("unfollow_users")}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
