import React from "react";
import ProfileAvatar from "./ProfileAvatar";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";

function ExtendProfileAvatar(props) {
    return (
        <Box
            sx={{
                width: "70px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <ProfileAvatar name={props.name} img={props.img}/>
            <Typography
                variant="caption"
                noWrap
                sx={{
                    mt: 0.5,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "70px",
                    fontFamily:"Abel"
                }}
            >
                {props.name}
            </Typography>
        </Box>
    );
}

export default ExtendProfileAvatar;