import React from 'react'
import PagesHeader from "../components/PagesHeader";
import TipsStack from '../components/TipsStack';
import { Typography } from '@mui/material';


function Tips() {
  return (
    <div>
      <PagesHeader txt="Tips" />
      <Typography
        sx={{
          fontFamily: "Poppins",
          color: "#F25B38",
          fontSize: "15px",
          width: "90%",
          margin: "0 auto",
          textAlign: "center",
          paddingTop: 2,
          paddingBottom: 2,

        }} >
        Here you can find all kinds of tips that will
        help you reduce the use of social networks
      </Typography>
      <TipsStack />
    </div>
  )
}

export default Tips