import React from 'react';

const arrowContainerStyle = {
  position: 'fixed',
  bottom: '130px',  // This positions the bottom of the arrow a fixed distance from the bottom of the viewport
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,  // Make sure it's above most other elements
};

const arrowStyle = {
  position: 'relative',
  height: '60px',  // Controls the height of the line part of the arrow
  width: '2px',  // Controls the width of the line part of the arrow
  backgroundColor: 'black',  // Line color
  animation: 'bounce 1s infinite'
};

const arrowHeadStyle = {
    position: 'absolute',
    bottom: '0',  // Aligns the base of the arrowhead with the bottom of the line
    left: '50%',
    marginLeft: '-10px',  // Center the arrowhead on the line
    width: '0',
    height: '0',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '15px solid black',  // Adjusted to make the arrowhead point down
  };
  

const keyframesStyle = `
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);  // Bounce up
  }
  60% {
    transform: translateY(-5px);  // Bounce up
  }
}
`;

const AnimatedArrow = () => {
  return (
    <>
      <style>{keyframesStyle}</style>
      <div style={arrowContainerStyle}>
        <div style={arrowStyle}>
          <div style={arrowHeadStyle}></div>
        </div>
      </div>
    </>
  );
};

export default AnimatedArrow;
