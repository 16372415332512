import React from 'react';
import PagesHeader from "../components/PagesHeader";
import { Typography } from '@mui/material';

function AboutUs(props) {
  return (
    <div>
      <PagesHeader txt="About-Us" />

      <Typography
        component="div"  // <-- Change here
        sx={{
          fontFamily: "Poppins",
          color: "black",
          fontSize: "15px",
          width: "80%",
          marginLeft: "10%",
          marginRight: '10%',
          textAlign: "left",
          paddingTop: 2,
          '& span': {
            color: "#F25B38",
            marginBottom: '10px'
          },
          '& ul': {
            paddingLeft: '16px',
            marginTop: '5px',
          }
        }}
      >
        <span>We are</span> a team of passionate developers who are committed to creating the best possible app for our users. We believe that technology can be used to make people's lives easier and more enjoyable, and we are excited to be a part of that.
        <br /><br />
        <span>Our app</span> is designed to help people stay connected with their friends and family, learn new things, and be more productive. We believe that our app can make a positive impact on the world, and we are committed to making it the best it can be.
        <br /><br />
        <span>Our Mission</span> is to create a world where everyone has access to the information and tools they need to live their best lives. We believe that technology can be a powerful force for good, and we are committed to using our app to make a positive impact on the world.
        <br /><br />
        <span>Our Values</span>
        <br />
        We believe in the following values:
        <ul>
          <li>Innovation: We are always looking for new ways to improve our app and make it more user-friendly.</li>
          <li>Collaboration: We believe that the best ideas come from working together, and we are committed to collaborating with our users and partners to make our app the best it can be.</li>
          <li>Integrity: We believe in being honest and transparent with our users, and we are committed to building a trustworthy relationship with them.</li>
        </ul>
        <br />
        <span>Contact Us</span>
        <br />
        If you have any questions about our app or our company, please contact us at:
        <br /><span style={{ color: 'blue' }}>socialordercompany@gmail.com</span>
        <br /><br />
      </Typography>
    </div>
  )
}

export default AboutUs;
